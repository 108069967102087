<template>
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo" :hasBackButton="true" backPath="/atividades-especificas">
        <template #content>
            <AppFormCadastro
                :service="service"
                :form="form"
                backPath="/atividades-especificas"
                @onBeforeSave="onBeforeSave"
                @onAfterSave="onAfterSave"
                @onLoadDataEdit="onLoadDataEdit"
                @onValidate="onValidate"
            >
                <template #content>
                    <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                        <AppInfoManual nomeTelaDoManual="atividade-especifica-form" />
                        <h3 class="ml-1">{{ title }}</h3>
                    </div>
                    <div class="">
                        <div class="field">
                            <label for="nome">Nome atividade</label>
                            <InputText id="nome" v-model.trim="form.nome" autocomplete="off" :class="{ 'p-invalid': submitted && !form.nome }" />
                            <small class="p-error" v-if="submitted && !form.nome">Nome da atividade é obrigatória.</small>
                        </div>

                        <div class="field">
                            <span :style="{ color: 'red' }">*</span>
                            <label for="description">Descrição da atividade</label>
                            <Textarea
                                v-model.trim="form.descricao"
                                autocomplete="off"
                                :autoResize="true"
                                rows="3"
                                :class="{ 'p-invalid': submitted && !form.descricao }"
                            />
                            <small class="p-error" v-if="submitted && !form.descricao">Descrição é obrigatório.</small>
                        </div>
                    </div>
                </template>
            </AppFormCadastro>
        </template>
    </AppPanel>
</template>

<script>
import SesmtService from '@/services/SesmtService';
import { getCurrentCompany } from '@/services/store';
import AppInfoManual from '../../components/AppInfoManual.vue';

export default {
    data() {
        return {
            form: {},
            submitted: false,
            customers: []
        };
    },
    created() {
        this.service = new SesmtService('/atividades-especificas');
    },
    methods: {
        async onBeforeSave() {
            this.submitted = true;
        },
        async onLoadDataEdit(data) {
            this.form = data;
        },
        onValidate(cbSuccess, cbError) {
            const valid = Boolean(this.form.nome && this.form.descricao);

            if (!valid) {
                cbError();
                return;
            }

            cbSuccess();
        }
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar atividade específica' : 'Criar atividade específica';
        },
        getCurrentCompany() {
            return getCurrentCompany();
        }
    },
    watch: {
        getCurrentCompany(newValue, oldValue) {
            if (newValue !== oldValue) {
                alert('Não é possível trocar a empresa no fluxo de criação/edição de um cadastro!');
                this.$router.push({ path: 'list' });
            }
        }
    },
    components: { AppInfoManual }
};
</script>
